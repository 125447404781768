import React from "react";
import { Link } from "react-router-dom";
import { navDemo, navPagesData } from "../../../utils/data";

export default function OffCanvas() {
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasWithBackdrop"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel">
          <Link
            to="/"
            className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img src="assets/img/logo.png" alt="logo" className="img-fluid" />
          </Link>
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <span>
            <i className="fa-solid fa-xmark"></i>
          </span>
        </button>
      </div>
      <div className="offcanvas-body">
        <ul className="nav col-12 col-md-auto justify-content-center main-menu">
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Home
            </Link>
            <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
              <div className="dropdown-grid rounded-custom width-half">
                <div className="dropdown-grid-item">
                  {navDemo.map((data, i) => (
                    <Link
                      to={data.path}
                      className="dropdown-link demo-link"
                      key={i + 1}
                    >
                      <span className="demo-list secondary-bg rounded-circle text-white">
                        {i + 1}
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">{data.menuTitle}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </li>
          <li>
            <Link to="/activity" className="nav-link">
              Activity
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Collectors
            </Link>
            <div className="dropdown-menu border-0 rounded-custom shadow py-0">
              <div className="dropdown-grid rounded-custom width-full-3">
                <div className="dropdown-grid-item first-item">
                  <Link
                    to="/explore-collections"
                    className="mega-list-item d-flex align-items-center"
                  >
                    <img
                      src="assets/img/mega-thumb-1.png"
                      alt="collector"
                      className="img-fluid rounded-circle"
                    />
                    <div className="right-content">
                      <h6>Trading Card</h6>
                      <span>140 items</span>
                    </div>
                  </Link>
                  <Link
                    to="/explore-collections"
                    className="mega-list-item d-flex align-items-center"
                  >
                    <img
                      src="assets/img/mega-thumb-2.png"
                      alt="collector"
                      className="img-fluid rounded-circle"
                    />
                    <div className="right-content">
                      <h6>Domain names</h6>
                      <span>125 items</span>
                    </div>
                  </Link>
                  <Link
                    to="/explore-collections"
                    className="mega-list-item d-flex align-items-center"
                  >
                    <img
                      src="assets/img/mega-thumb-3.png"
                      alt="collector"
                      className="img-fluid rounded-circle"
                    />
                    <div className="right-content">
                      <h6>Photography</h6>
                      <span>135 items</span>
                    </div>
                  </Link>
                  <Link
                    to="/explore-collections"
                    className="mega-list-item d-flex align-items-center"
                  >
                    <img
                      src="assets/img/mega-thumb-5.png"
                      alt="collector"
                      className="img-fluid rounded-circle"
                    />
                    <div className="right-content">
                      <h6>Collectibles</h6>
                      <span>155 items</span>
                    </div>
                  </Link>
                </div>
                <div className="dropdown-grid-item second-item pt-0">
                  <Link
                    to="/explore-collections"
                    className="mega-list-item d-flex align-items-center"
                  >
                    <img
                      src="assets/img/mega-thumb-4.png"
                      alt="collector"
                      className="img-fluid rounded-circle"
                    />
                    <div className="right-content">
                      <h6>virtual Worlds</h6>
                      <span>140 items</span>
                    </div>
                  </Link>
                  <Link
                    to="/explore-collections"
                    className="mega-list-item d-flex align-items-center"
                  >
                    <img
                      src="assets/img/mega-thumb-5.png"
                      alt="collector"
                      className="img-fluid rounded-circle"
                    />
                    <div className="right-content">
                      <h6>Collections</h6>
                      <span>146 items</span>
                    </div>
                  </Link>
                  <Link
                    to="/explore-collections"
                    className="mega-list-item d-flex align-items-center"
                  >
                    <img
                      src="assets/img/mega-thumb-6.png"
                      alt="collector"
                      className="img-fluid rounded-circle"
                    />
                    <div className="right-content">
                      <h6>Trading Card</h6>
                      <span>170 items</span>
                    </div>
                  </Link>
                  <Link
                    to="/explore-collections"
                    className="mega-list-item d-flex align-items-center"
                  >
                    <img
                      src="assets/img/mega-thumb-2.png"
                      alt="collector"
                      className="img-fluid rounded-circle"
                    />
                    <div className="right-content">
                      <h6>Solana NFTs</h6>
                      <span>160 items</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Blog
            </Link>
            <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-dark">
              <div className="dropdown-grid rounded-custom width-half">
                <div className="dropdown-grid-item">
                  <Link to="/blog-grid" className="dropdown-link px-0">
                    <span className="drop-title">Blog Grid</span>
                  </Link>
                  <Link to="/blog-list" className="dropdown-link px-0">
                    <span className="drop-title">Blog List</span>
                  </Link>
                  <Link to="/blog-details" className="dropdown-link px-0">
                    <span className="drop-title">Blog Details</span>
                  </Link>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Pages
            </Link>
            <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
              <div className="dropdown-grid rounded-custom width-full">
                <div className="dropdown-grid-item">
                  {navPagesData.map((data, i) => (
                    <Link
                      key={i + 1}
                      to={data.path}
                      className="dropdown-link px-0"
                    >
                      <span className="drop-title">{data.title}</span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </li>
          <li>
            <Link to="/contact-us" className="nav-link">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
