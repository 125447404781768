import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { MediaRenderer, useAddress, useContract, useContractRead, Web3Button, lightTheme } from "@thirdweb-dev/react";
import { HERO_IMAGE_URL, LOTTERY_CONTRACT_ADDRESS } from "../constants/addresses";
import PrizeNFT from "../RaffleComponents/PrizeNFT";
import ErrorMessagePopup from "../popups/ErrorMessagePopup";
import SuccessMessagePopup from "../popups/SuccessMessagePopup";
import CountdownTimer from "../CoundownTimer";

const Raffle = () => {
    const targetDate = new Date('2024-12-12T11:00:00');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const address = useAddress();
    const { contract } = useContract(LOTTERY_CONTRACT_ADDRESS);
    const { data: lotteryStatus } = useContractRead(contract, "lotteryStatus");
    const { data: ticketCost, isLoading: ticketCostLoading } = useContractRead(contract, "ticketCost");
    const ticketCostInEther = ticketCost ? ethers.utils.formatEther(ticketCost) : "0";
    const { data: totalEntries, isLoading: totalEntriesLoading } = useContractRead(contract, "totalEntries");

    const handleConnectWallet = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            setSuccessMessage('Wallet connected successfully');
        } catch (error) {
            setErrorMessage('Error: Unable to connect wallet.');
        }
    };

    const handleBuyTickets = async () => {
        if (!address || !contract) {
            setErrorMessage("Please connect your wallet first.");
            return;
        }

        try {
            const value = ethers.utils.parseEther(ticketCostSubmit.toString());
            await contract.call("buyTicket", [ticketAmount], { value });
            setSuccessMessage('Entry successfully submitted!');
        } catch (error) {
            setErrorMessage('Error: Transaction rejected or insufficient funds.');
        }
    };

    const increaseTicketAmount = () => setTicketAmount(ticketAmount + 1);
    const decreaseTicketAmount = () => ticketAmount > 1 && setTicketAmount(ticketAmount - 1);

    const [ticketAmount, setTicketAmount] = useState(1);
    const ticketCostSubmit = parseFloat(ticketCostInEther) * ticketAmount;

    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => setErrorMessage(''), 4000);
            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(''), 4000);
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    return (
        <section id="nft-raffle" className="hero-section-two position-relative overflow-hidden pt-80">
            <div className="text-center">
                <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
                    <h2 className="mb-0">NFT Giveaways</h2>
                </div>
                <p>
                    On Polygon Network
                </p>
            </div>
            <div className="container-fluid custom-container position-relative overflow-hidden">
                <div className="row">
                    <div className="col-md-6">
                        <div className="image">
                            {lotteryStatus ? (
                                <PrizeNFT />
                            ) : (
                                <MediaRenderer
                                    src={HERO_IMAGE_URL}
                                    width="100%"
                                    height="auto"
                                />
                            )}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div>
                            <p style={{ fontSize: "20px", color: "white" }}>Win The NFT</p>
                            <p>
                                Purchase entries for a chance to win an exclusive NFT! Each entry brings you one step closer to winning this highly sought-after digital collectible. The winner will be carefully selected and the NFT will be transferred directly to their wallet.
                            </p>
                            <p>
                                Remember, the more entries you purchase, the higher your chances of securing the prize. Increase your odds of winning!
                            </p>
                        </div>

                        <div style={{ marginBottom: "30px" }}>
                            {!ticketCostLoading && (
                                <p>Ticket Price: <span style={{ color: "yellow", textAlign: "center" }}>{ticketCostInEther} POL</span></p>
                            )}
                        </div>

                        <div>
                            <CountdownTimer targetDate={targetDate} />
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <div className="d-flex justify-content-center align-items-center">
                                <button style={{ cursor: "pointer", color: "white", marginRight: "15px", border: "1px solid grey", borderRadius: "5px", width: "30px", height: "30px" }} className="decrease" onClick={decreaseTicketAmount}>-</button>
                                <span style={{ color: "white" }} className="summ">{ticketAmount}</span>
                                <button style={{ cursor: "pointer", color: "white", marginLeft: "15px", border: "1px solid grey", borderRadius: "5px", width: "30px", height: "30px" }} className="increase" onClick={increaseTicketAmount}>+</button>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "30px" }}>
                            {successMessage && <SuccessMessagePopup message={successMessage} onClose={() => setSuccessMessage('')} />}
                            {errorMessage && <ErrorMessagePopup message={errorMessage} onClose={() => setErrorMessage('')} />}
                            <Web3Button
                                connectWallet={{
                                    btnTitle: "Mint Now",
                                    modalTitle: "Evil Kongs",
                                    modalSize: "compact",
                                    modalTitleIconUrl: "/assets/img/fabicon.png",
                                    showThirdwebBranding: false,
                                }}
                                theme={lightTheme({
                                    colors: {
                                        modalBg: "#1c1f26",
                                        borderColor: "transparent",
                                        separatorLine: "#282b2f",
                                        secondaryText: "#c4c4c4",
                                        primaryText: "#ffffff",
                                        connectedButtonBg: "transparent",
                                        primaryButtonBg: "#1c1f26",
                                        primaryButtonText: "#ffffff",
                                        secondaryButtonHoverBg: "#282b2f",
                                        connectedButtonBgHover: "transparent",
                                        walletSelectorButtonHoverBg: "#282b2f",
                                        secondaryButtonText: "#ffffff",
                                        secondaryButtonBg: "#282b2f",
                                    },
                                })}
                                style={{ border: "1px solid grey", borderRadius: "5px", width: "150px" }}
                                contractAddress={LOTTERY_CONTRACT_ADDRESS}
                                action={(contract) => contract.call(
                                    "buyTicket",
                                    [ticketAmount],
                                    { value: ethers.utils.parseEther(ticketCostSubmit.toString()) }
                                )}
                                isDisabled={!lotteryStatus}
                            >Buy Ticket(s)</Web3Button>
                        </div>

                        <div>
                            {!totalEntriesLoading && (
                                <p style={{ textAlign: "center", marginTop: "20px" }}>Sold Tickets: {totalEntries.toString()}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Raffle;
