import React from "react";
import { Link } from "react-router-dom";

const HeroTwo = () => {
  return (
    <section className="hero-section-two position-relative overflow-hidden pt-80">
      <div className="container-fluid custom-container position-relative overflow-hidden">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <h1 className="display-4 fw-bold">
                  Evil Kongs
                </h1>
                <p>
                  Evil Kongs, in its new era as a mint aggregator, focuses on Polygon and Arbitrum collections, providing a platform that centralizes and streamlines NFT minting. In addition to supporting external projects, it also features its own collections, solidifying its position as an innovative leader in the Web3 ecosystem.
                </p>
                <div className="hero-btns">
                  <div className="hero-secondary-btn d-inline-block mt-3 me-3">
                    <a href="#collections">Explore items</a>
                  </div>
                  <div className="hero-secondary-btn d-inline-block mt-3 me-3">
                    <a href="https://x.com/EvilKongsNFTs" target="_blank" rel="noopener noreferrer">
                      Get Featured
                    </a>
                  </div>
                  <div className="hero-secondary-btn d-inline-block mt-3">
                    <a href="#nft-raffle">NFT Giveaway</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="hero-right-img">
                <img
                  src="assets/img/home2/hero-art.png"
                  alt="hero"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroTwo;
