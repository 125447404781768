import React from "react";
import VideoBlog from "../../components/blogs/VideoBlog";
import BrandOne from "../../components/brands/BrandOne";
import TrendingCollection from "../../components/collections/TrendingCollection";
import NewsLetter from "../../components/cta/NewsLetter";
import HotDrops from "../../components/HotDrops";
import Layout from "../../components/layout/Layout";
import TrendingCategory from "../../components/TrendingCategory";
import Raffle from "../../components/collections/NFTRaffle";
import HeroTwo from "./HeroTwo";

const HomeTwo = () => {
  return (
    <Layout>
      <HeroTwo />
      <TrendingCollection />
      <HotDrops />
      <TrendingCategory />
      <Raffle />
      <NewsLetter />
      <BrandOne />
      <VideoBlog />
    </Layout>
  );
};

export default HomeTwo;
