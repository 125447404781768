import React from "react";
import Routers from "./routers";
import {
  ThirdwebProvider,
  metamaskWallet,
  trustWallet,
  walletConnect,
  okxWallet,
  coinbaseWallet,
} from "@thirdweb-dev/react";
import { Polygon, Base } from "@thirdweb-dev/chains";

function App() {
  return (
    <ThirdwebProvider
      autoConnect={true}
      activeChain={Polygon}
      supportedChains={[Polygon, Base]}
      supportedWallets={[
        metamaskWallet(),
        trustWallet(),
        okxWallet(),
        coinbaseWallet(),
        walletConnect(),
      ]}
      clientId={process.env.NEXT_PUBLIC_TEMPLATE_CLIENT_ID} // Ajusta la variable de entorno
    >
      <Routers />
    </ThirdwebProvider>
  );
}

export default App;
